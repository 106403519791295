.App {
  /* text-align: center; */
  height: 100vh;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  min-width: 396px;
  max-width: 396px;
  background: #515355;
  color: #fff;
  margin-left: -316px;
  transition: all 0.5s;
  height: 100%;
  z-index: 100;
}

.sidebar2 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  min-width: 396px;
  max-width: 396px;
  background: black;
  color: #fff;
  margin-left: -396px;
  transition: all 0.5s;
  height: 100%;
  z-index: 50;
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
}

.sidebar2.is-open {
  margin-left: 0px;
  transition: 0.5s;
  left: 396px;
}

.sidebar2.is-open-lite {
  margin-left: 0px;
  transition: 0.5s;
  left: 80px;
}

.Header {
  background-color: #DB632B;
  height: 100px;
}

.Maps {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align:  center;
  /* height: 100vh; */
  /* margin: auto; */
}

.Maps p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}